import "./map_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

export class Controller extends BaseController {
  static targets = ["map"];
  static values = { coordinates: { type: Array, default: [2.8094, 45.5750] } };

  connect() {
    this.map = new maplibregl.Map({
      center: this.coordinatesValue,
      container: this.mapTarget,
      locale: document.documentElement.lang,
      style: 'https://api.maptiler.com/maps/da255326-71c7-42aa-ae18-2c472015e169/style.json?key=xJfyVrC29wRP9anAe2jn',
      zoom: 14,
    });

    this.addNavControl();
    this.enableZoomOnClick();
    this.addMarker();
  }

  disconnect() {
    this.map.remove();
  }

  addNavControl() {
    this.map.addControl(new maplibregl.NavigationControl());
  }

  enableZoomOnClick() {
    this.map.scrollZoom.disable();

    this.map.on('click', (_) => {
      this.map.scrollZoom.enable();
    });
  }

  addMarker() {
    const marker = new maplibregl.Marker({
      color: "#1CB1CD",
    })
    .setLngLat(this.coordinatesValue)
    .addTo(this.map);
  }
}
