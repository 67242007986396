import "./switch_box_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["input"];

  connect() {
    this.change();
  }

  change() {
    this.conditionalElement.hidden = !this.inputTarget.checked;
  }

  get conditionalElement() {
    const selector = this.element.dataset.conditionalElement;
    return document.querySelector(selector);
  }
}
