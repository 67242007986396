import "./header_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static classes = [ "current" ];
  static targets = [ "titleTrigger", "togglableNavItem" ];

  connect() {
    this.menuOpen = false;
  }

  // mobile menu
  openMobileMenu() {
    if (this.element.classList.contains("c-header-is-mobile-menu-closed")) {
      this.element.classList.remove("c-header-is-mobile-menu-closed");
      document.body.classList.add("no-scroll");
      this.titleTriggerTarget.innerText = "Fermer";
    } else {
      this.element.classList.add("c-header-is-mobile-menu-closed");
      document.body.classList.remove("no-scroll");
      this.titleTriggerTarget.innerText = "Menu";
      this.removeMenuFrame();
    }
  }

  // desktop submenu
  toggleMenu(e) {
    const target = e.target.closest("[data-header-target='togglableNavItem']");
    const src = target.dataset.menuSrc;
    const current = target.classList.contains(this.currentClass);
    const open = target.getAttribute("aria-expanded") == "true";

    this.disableAllNavItems();

    if (src) {
      e.preventDefault();

      if (open) {
        this.menuOpen = false;
        this.removeMenuFrame();
      } else {
        this.menuOpen = true;
        target.classList.add(this.currentClass);
        target.setAttribute("aria-expanded", "true");
        this.menuFrame.setAttribute("src", src);
      }
    } else {
      this.menuOpen = false;
      this.removeMenuFrame();
      target.classList.add(this.currentClass);
    }
  }

  closeMenu(e) {
    if (!this.menuOpen) { return; }
    if (e && e.target && e.target.closest(".c-header-external-wrapper")) { return; }

    this.disableAllNavItems();
    this.removeMenuFrame();
  }

  removeMenuFrame() {
    while (this.menuFrame.firstChild) {
      this.menuFrame.removeChild(this.menuFrame.firstChild);
    }
    this.menuFrame.removeAttribute("src");
  }

  disableAllNavItems() {
    this.currentNavItems.forEach(i => {
      i.classList.remove(this.currentClass);
    });
    this.expandedNavItems.forEach(i => {
      i.setAttribute("aria-expanded", "false");
    });
  }

  get menuFrame() {
    return document.getElementById("header--menu");
  }

  get currentNavItems() {
    return this.togglableNavItemTargets.filter(i => i.classList.contains(this.currentClass));
  }

  get expandedNavItems() {
    return this.togglableNavItemTargets.filter(i => i.getAttribute("aria-expanded") == "true");
  }
}
