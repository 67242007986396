import "./header_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  toggleTheme() {
    const dark = document.documentElement.classList.toggle("dark-theme");
    document.documentElement.classList.toggle("light-theme");

    // TODO: save theme to user account
    console.log(dark ? "dark" : "light");
  }
}
