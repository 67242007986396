import "./carousel_component.css";
import Flickity from "flickity";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["gallery"];

  connect() {
    this.flkty = new Flickity(this.galleryTarget, {
      freeScroll: true,
      contain: true,
      groupCells: true,
      pageDots: false,
      prevNextButtons: false,
    });
  }

  disconnect() {
    this.flkty.destroy();
  }

  previous() {
    this.flkty.previous();
  }

  next() {
    this.flkty.next();
  }
}
