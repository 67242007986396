import "./link_select_component.css";
import '../../../../../vendor/tom_select/css/tom-select.custom.css';
import TomSelect from "tom-select";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    const urlPattern = /^https?:\/\/[^ "]+$/;
    const config = {
      addPrecedence: false,
      create: true,
      createFilter: urlPattern,
    };
    this.tomSelect = new TomSelect(this.element, config);
  }

  disconnect() {
    this.tomSelect.destroy();
  }
}
