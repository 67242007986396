import "wicg-inert";
import "./bulletin_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["text", "image", "button"];

  connect() {
    this.expanded = true;
    const leeway = 50;

    if (!this.hasImageTarget || this.textHeight <= (this.imageHeight + leeway)) return;

    this.textTarget.inert = true;
    this.expanded = false;
    this.textTarget.setAttribute("aria-expanded", "false");
    this.buttonTarget.removeAttribute("hidden");
  }

  toggle() {
    this.textTarget.setAttribute("aria-expanded", this.expanded ? "false" : "true");

    const alternateText = this.buttonTarget.getAttribute("data-alternate-text");
    const currentText = this.buttonTarget.innerText;
    this.buttonTarget.innerHTML = alternateText;
    this.buttonTarget.setAttribute("data-alternate-text", currentText);

    this.textTarget.inert = this.expanded;
    this.expanded = !this.expanded;
  }

  get imageHeight() {
    return this.hasImageTarget ? parseFloat(getComputedStyle(this.imageTarget, null).height.replace("px", "")) : 0;
  }

  get textHeight() {
    return parseFloat(getComputedStyle(this.textTarget, null).height.replace("px", ""));
  }
}
