import { Controller } from "@hotwired/stimulus"
import { decodeBlurHash } from "fast-blurhash";

export default class extends Controller {
  static values = { blurhash: String };

  connect() {
    if (this.hasBlurhashValue) {
      this.processBlurhash();
    }
  }

  disconnect() {
  }

  processBlurhash() {
    const pixels = decodeBlurHash(this.blurhashValue, 3, 3);
    const bottom = pixels.slice(28, 31);

    if (this.isTooLight(...bottom)) {
      this.element.classList.add("is-light");
    }
  }

  isTooLight(r, g, b, a) {
    const hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    return hsp > 175;
  }
}
