import L from "leaflet";
import RasterCoords from "leaflet-rastercoords";
import "./station_component.css";
import "leaflet/dist/leaflet.css";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["map"];

  connect() {
    const img = this.getImageDimensions();
    const map = this.initializeMap();
    const rc = new RasterCoords(map, img);
    this.configureMap(map, rc, img);
  }

  disconnect() {}

  getImageDimensions() {
    if (this.tilesValue == "pistes") {
      return [10588, 5372];
    } else if (this.tilesValue == "activities") {
      return [4988, 4761];
    }
  }

  initializeMap() {
    return L.map(this.mapTarget, {
      attributionControl: false,
      crs: L.CRS.Simple,
      zoomControl: false,
    });
  }

  configureMap(map, rc, img) {
    map.setMaxZoom(rc.zoomLevel());
    map.setMinZoom(2);

    L.control.zoom({ position: "topright" }).addTo(map);
    map.setView(rc.unproject([img[0] / 2, img[1] / 2]), this.defaultZoom);

    L.tileLayer(
      `https://{s}.tiles.lemontdore.fr/${this.tilesValue}/{z}/{x}/{y}.png`,
      {
        noWrap: true,
        bounds: rc.getMaxBounds(),
        maxNativeZoom: rc.zoomLevel(),
      }
    ).addTo(map);
  }

  get defaultZoom() {
    const width = document.documentElement.clientWidth;
    if (width > 2000) {
      return 4;
    } else if (width > 1000) {
      return 3;
    } else {
      return 2;
    }
  }

  get tilesValue() {
    return this.element.getAttribute("data-tiles");
  }
}
