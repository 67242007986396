import "./image_field_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
import {
  Core,
  ThumbnailGenerator,
  XHRUpload,
} from 'uppy';
import French from '@uppy/locales/lib/fr_FR';
import 'uppy/dist/uppy.min.css';

export class Controller extends BaseController {
  static targets = ["content", "fileInput", "hiddenInput", "preview"];

  connect() {
    this.uppy = new Core({
      allowMultipleUploadBatches: false,
      autoProceed: true,
      locale: French,
      maxNumberOfFiles: 1,
      // TODO: add restrictions
      // see https://uppy.io/docs/uppy/#restrictions
      // restrictions: []
    })
    .use(ThumbnailGenerator, {
      thumbnailHeight: 113,
      thumbnailWidth: 248,
    })
    .use(XHRUpload, {
      endpoint: '/admin/upload',
    });

    this.uppy.on('thumbnail:generated', (file, preview) => {
      while (this.previewTarget.firstChild) {
        this.previewTarget.removeChild(this.previewTarget.firstChild);
      }

      if (preview) {
        const previewImage = document.createElement("img");
        previewImage.src = preview;
        this.previewTarget.appendChild(previewImage);
      }
    });

    this.uppy.on('upload-success', (file, response) => {
      const uploadedFileData = response.body['data'];

      this.hiddenInputTarget.value = JSON.stringify(uploadedFileData);
    });

    this.uppy.on('file-removed', () => {
      this.fileInputTarget.value = null;
    });

    this.uppy.on('complete', () => {
      this.fileInputTarget.value = null;
    });
  }

  disconnect() {
    this.uppy.close();
    this.uppy = null;
  }

  addFiles(event) {
    const files = Array.from(event.target.files);

    this.uppy.reset();

    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err);
        } else {
          // handle other errors
          console.error(err);
        }
      }
    });
  }
}
