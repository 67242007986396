import "./content_editor_component.css"
import { Editor } from '@tiptap/core';
import FloatingMenu from '@tiptap/extension-floating-menu';
import Link from '@tiptap/extension-link';
// import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import { Controller as BaseController } from "@hotwired/stimulus";
import { TableOfContents } from './extensions/table_of_contents.js'
import PromptsBlock from './extensions/prompts_block.js';
import ImageBlock from './extensions/image_block.js';

export class Controller extends BaseController {
  static classes = ["active", "empty"];
  static targets = ["editorContent", "editorJson", "floatingMenu", "hiddenPromptsButton", "hiddenImageButton", "hiddenLinkButton", "contentInserter", "linkInserter"];
  static values = {
    placeholder: String
  };

  connect() {
    this.editor = new Editor({
      element: this.editorContentTarget,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [2, 3, 4],
          }
        }),
        // Placeholder.configure({
        //   emptyEditorClass: this.emptyClass,
        //   placeholder: this.placeholderValue,
        // }),
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            'data-action': 'admin--form--content-editor#selectLink',
            target: null,
          },
        }),
        TableOfContents,
        this.hasFloatingMenuTarget ? FloatingMenu.configure({
          element: this.floatingMenuTarget,
        }) : null,
        ImageBlock,
        PromptsBlock,
      ],
      content: this.contentJson,
    });

    this.editor.on("update", () => this.updateContent());
    this.editor.on("selectionUpdate", (e) => this.updateSelection(e));
    this.updateContent();
  }

  disconnect() {
    this.editor.off("update", () => this.updateContent());
    this.editor.off("selectionUpdate", () => this.updateSelection());
    this.editor.destroy();
    this.editor = null;
  }

  focus() {
    this.editor.chain().focus().run();
  }

  updateContent() {
    this.editorJsonTarget.value = JSON.stringify(this.editor.getJSON());
  }

  updateSelection(e) {
    const buttons = [
      { key: 'bold' },
      { key: 'italic' },
      { key: 'strike' },
      { key: 'code' },
      { key: 'link' },
      { key: 'paragraph' },
      { key: 'h1', command: 'heading', params: { level: 1 } },
      { key: 'h2', command: 'heading', params: { level: 2 } },
      { key: 'h3', command: 'heading', params: { level: 3 } },
      { key: 'h4', command: 'heading', params: { level: 4 } },
      { key: 'h5', command: 'heading', params: { level: 5 } },
      { key: 'h6', command: 'heading', params: { level: 6 } },
      { key: 'bulletList' },
      { key: 'orderedList' },
      { key: 'codeBlock' },
      { key: 'blockquote' },
    ];

    buttons.forEach(b => {
      const button = this.element.querySelector('button.' + b.key);

      if (!button) return;

      const active = this.editor.isActive(b.command || b.key, b.params || {});

      button.classList.toggle(this.activeClass, active);
    });
  }

  toggleBold(e) {
    this.editor.chain().focus().toggleBold().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('bold'))
  }

  toggleItalic(e) {
    this.editor.chain().focus().toggleItalic().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('italic'))
  }

  toggleStrike(e) {
    this.editor.chain().focus().toggleStrike().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('strike'))
  }

  toggleCode(e) {
    this.editor.chain().focus().toggleCode().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('code'))
  }

  selectLink() {
    this.editor.commands.extendMarkRange('link');
  }

  toggleLink(e) {
    this.selectLink();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('link'))

    const attributes = this.editor.getAttributes('link');
    attributes.text = this.currentlySelectedText();

    const url = this.hiddenLinkButtonTarget.dataset.basePath;
    this.hiddenLinkButtonTarget.href = url + '&' + this.linkParams(attributes);
    this.hiddenLinkButtonTarget.click();
  }

  linkParams(attributes) {
    const linkAttributes = Object.fromEntries(
      Object.entries(attributes).map(([k, v]) => [`link[${k}]`, v])
    );
    return new URLSearchParams(linkAttributes).toString();
  }

  currentlySelectedText() {
    const state = this.editor.state;
    const text = state.doc.textBetween(state.selection.from, state.selection.to);

    return text;
  }

  linkInserterTargetConnected(t) {
    const params = JSON.parse(t.dataset.params);

    this.editor.chain().focus().setLink({
      href: params.href,
      target: params.target,
      title: params.title,
    }).run();

    t.parentNode.removeChild(t);
  }

  unsetAllMarks() {
    this.editor.chain().focus().unsetAllMarks().run();
  }

  clearNodes() {
    this.editor.chain().focus().clearNodes().run();
  }

  setParagraph(e) {
    this.editor.chain().focus().setParagraph().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('paragraph'))
  }

  toggleHeading(e) {
    const level = parseInt(e.target.dataset.level, 10);
    this.editor.chain().focus().toggleHeading({ level: level }).run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('heading', { level: level }))
  }

  toggleBulletList(e) {
    this.editor.chain().focus().toggleBulletList().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('bulletList'))
  }

  toggleOrderedList(e) {
    this.editor.chain().focus().toggleOrderedList().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('orderedList'))
  }

  toggleCodeBlock(e) {
    this.editor.chain().focus().toggleCodeBlock().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('codeBlock'))
  }

  toggleBlockquote(e) {
    this.editor.chain().focus().toggleBlockquote().run();
    e.target.classList.toggle(this.activeClass, this.editor.isActive('blockquote'))
  }

  setHorizontalRule() {
    this.editor.chain().focus().setHorizontalRule().run();
  }

  setHardBreak() {
    this.editor.chain().focus().setHardBreak().run();
  }

  undo() {
    this.editor.chain().focus().undo().run();
  }

  redo() {
    this.editor.chain().focus().redo().run();
  }

  togglePrompts(e) {
    const params = e.target.closest("button").dataset.params;
    const url = this.hiddenPromptsButtonTarget.dataset.basePath;
    this.hiddenPromptsButtonTarget.href = url + (params ? '&' + params : '');
    this.hiddenPromptsButtonTarget.click();
  }

  contentInserterTargetConnected(t) {
    const content = t.innerHTML;
    this.editor.commands.insertContent(content);
    t.parentNode.removeChild(t);
  }

  toggleImage(e) {
    const params = e.target.closest("button").dataset.params;
    const url = this.hiddenImageButtonTarget.dataset.basePath;
    this.hiddenImageButtonTarget.href = url + (params ? '&' + params : '');
    this.hiddenImageButtonTarget.click();
  }

  get contentJson() {
    const { value } = this.editorJsonTarget;

    if (value == "") {
      return "";
    } else {
      return JSON.parse(value);
    }
  }
}
