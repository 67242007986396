import "./grouped_collection_select_component.css";
import '../../../../../vendor/tom_select/css/tom-select.custom.css';
import TomSelect from "tom-select";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    const config = {
      plugins: ['checkbox_options'],
    };
    this.tomSelect = new TomSelect(this.element, config);
  }

  disconnect() {
    this.tomSelect.destroy();
  }
}
