import "./toast_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    if (this.element.classList.contains("c-admin--toaster--toast-hidden")) {
      setTimeout(this.reveal.bind(this), 100);
      setTimeout(this.close.bind(this), 4500);
    }
  }

  close(e) {
    if (e) e.preventDefault();
    this.hide();
    setTimeout(this.destroy.bind(this), 500);
  }

  reveal() {
    this.element.classList.remove("c-admin--toaster--toast-hidden");
  }

  hide() {
    this.element.classList.add("c-admin--toaster--toast-closed");
  }

  destroy() {
    this.element.parentNode.removeChild(this.element);
  }
}
