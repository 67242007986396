import { Node, mergeAttributes } from '@tiptap/core'

function attributesToParams(attributes) {
  const params = Object.fromEntries(
    Object.entries(attributes).map(([k, v]) => [`image[${k}]`, v])
  );
  return new URLSearchParams(params).toString();
}

export default Node.create({
  name: 'imageBlock',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      alt: {
        default: null,
      },
      credit: {
        default: null,
      },
      file_data: {
        default: null,
      },
      file_url: {
        default: null,
      },
      text: {
        default: null,
      },
      size: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'image-block',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['image-block', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ({ editor, node, getPos, HTMLAttributes, decorations, extension }) => {
      const dom = document.createElement('image-block');
      const params = attributesToParams(node.attrs);

      dom.innerHTML = `
        <button type="button" data-action="admin--form--content-editor#toggleImage admin--layout#openPanel" data-params="${params}">
          <span class="c-admin--form--content-editor-content-image" data-size="${node.attrs.size}">
            <img src="${node.attrs.file_url}" alt="${node.attrs.alt}" />
          </span>
          ${node.attrs.text ? `<span>${node.attrs.text}</span>` : ''}
        </button>
      `;

      return {
        dom,
      }
    };
  },
})
