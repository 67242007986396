import "./layout_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';

export class Controller extends BaseController {
  static classes = ["open"];
  static targets = ["panel", "panelFrame", "panelCloser"];

  connect() {
    useClickOutside(this, { element: this.panelTarget })
  }

  disconnect() {
  }

  panelCloserTargetConnected() {
    this.closePanel();
  }

  openPanelWhenLoaded() {
    this.panelFrameTarget.loaded.then(() => this.openPanel());
  }

  openPanel() {
    this.panelTarget.classList.add(this.openClass);
  }

  closePanel() {
    this.panelTarget.classList.remove(this.openClass);
    this.emptyFrame();
  }

  clickOutside(e) {
    if (e.target.dataset.action && e.target.dataset.action.includes("admin--layout#openPanel")) return;
    this.closePanel();
  }

  emptyFrame() {
    while(this.panelFrameTarget.firstChild) {
      this.panelFrameTarget.removeChild(this.panelFrameTarget.firstChild);
    }
  }
}
