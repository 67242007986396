import "./ticker_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["time"];

  connect() {
    this.expanded = this.element.getAttribute("aria-expanded") == "true";

    this.connectTime();
    this.connectScroll();
  }

  disconnect() {
    this.disconnectTime();
    this.disconnectScroll();
  }

  connectTime() {
    if (!this.hasTimeTarget) return;

    this.updateTime();
    this.timeInterval = setInterval(() => this.updateTime(), 1000);
  }

  connectScroll() {
    if (!this.expanded) return;

    const viewportHeight = this.viewportHeight;
    let lastScrollPos = 0;
    let ticking = false;

    this.scrollListener = (e) => {
      lastScrollPos = window.scrollY;

      if (!ticking) {
        this.animationFrame = window.requestAnimationFrame(() => {
          if (lastScrollPos > viewportHeight && this.expanded) {
            this.toggle();
            this.disconnectScroll();
          }
          ticking = false;
        });
      }

      ticking = true;
    };

    window.addEventListener('scroll', this.scrollListener);
  }

  disconnectTime() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
      this.timeInterval = null;
    }
  }

  disconnectScroll() {
    if (this.scrollListener) {
      window.removeEventListener('scroll', this.scrollListener);
      this.scrollListener = null;
    }

    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
      this.animationFrame = null;
    }
  }

  updateTime() {
    this.timeTarget.innerHTML = this.currentTime;
  }

  toggle() {
    this.element.setAttribute("aria-expanded", this.expanded ? "false" : "true");
    this.expanded = !this.expanded;
  }

  remove() {
    while (this.turboFrame.firstChild) {
      this.turboFrame.removeChild(this.turboFrame.firstChild);
    }
    this.turboFrame.removeAttribute("src");
  }

  get currentTime() {
    const date = new Date();
    const options = {
      timeZone: "Europe/Paris",
      hour: "2-digit",
      minute: "2-digit",
    };
    const time = date.toLocaleTimeString(this.currentLocale, options);
    return time.replace(":", "<span>:</span>");
  }

  get currentLocale() {
    return document.documentElement.lang;
  }

  get turboFrame() {
    return this.element.closest("turbo-frame");
  }

  get viewportHeight() {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  }
}
