import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import slugify from 'slugify';

const isTargetNodeOfType = (node, type) => (node.type === type);

export const pluginKey = new PluginKey('tableOfContents')

export const TableOfContents = Extension.create({
  name: 'tableOfContents',

  addGlobalAttributes() {
    return [
      {
        types: [
          'heading',
        ],
        attributes: {
          id: {
            default: null,
          },
        },
        renderHTML: (attributes) => {
          console.log("renderHTML", attributes);
          return {
            id: attributes.id,
          };
        },
        parseHTML: (element) => {
          console.log("parseHTML", element);
          return element.id;
        },
      },
    ]
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: pluginKey,
        appendTransaction: (transactions, _prevState, nextState) => {
          const tr = nextState.tr;
          let modified = false;

          if (transactions.some((transaction) => transaction.docChanged)) {
            // Adds a unique id to a node
            nextState.doc.descendants((node, pos) => {
              const { heading } = nextState.schema.nodes;
              if (isTargetNodeOfType(node, heading)) {
                const attrs = node.attrs;
                // TODO: set locale
                const slug = slugify(node.textContent, { lower: true, strict: true, locale: 'fr' });
                tr.setNodeMarkup(pos, undefined, {...attrs, ['id']: slug});
                modified = true;
              }
            });
          }

          return modified ? tr : null;
        }
      })
    ];
  }
})
