import "./hero_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
import { decodeBlurHash } from "fast-blurhash";

export class Controller extends BaseController {
  static values = { blurhash: String };

  connect() {
    if (this.hasBlurhashValue) {
      this.renderBlurhash();
    }
  }

  renderBlurhash() {
    const pixels = decodeBlurHash(this.blurhashValue, this.dimensions.width, this.dimensions.height);
    const canvas = document.createElement('canvas');
    canvas.setAttribute("width", this.dimensions.width);
    canvas.setAttribute("height", this.dimensions.height);
    canvas.classList.add(`c-${this.identifier}-canvas`)
    const ctx = canvas.getContext('2d');
    const imageData = ctx.createImageData(this.dimensions.width, this.dimensions.height);
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
    this.element.prepend(canvas);
  }

  scroll(e) {
    const target = e.target.closest(".c-button-root");
    const scrollTarget = document.querySelector(target.dataset.scrollTarget);
    scrollTarget.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  get dimensions() {
    return {
      height: 32,
      width: 32,
    }
  }
}
