import "./tree_component.css";
import Sortable from 'sortablejs';
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["subtree"];
  static values = { reorderable: Boolean };

  connect() {
    if (this.reorderableValue) {
      this.connectReorderable();
    }
  }

  connectReorderable() {
    this.sortables = [];

    this.subtreeTargets.forEach(subtree => {
      this.sortables.push(
        Sortable.create(
          subtree,
          {
            animation: 150,
            draggable: ".c-admin--list--tree--item-root",
            fallbackOnBody: true,
            group: this.identifier,
            swapThreshold: 0.65,
            onMove: e => {
              // prevent dropping above first item content
              if (e.related.classList.contains('c-admin--list--tree--item-content')) return false;
            }
          }
        )
      );
    });
  }

  disconnect() {
    if (this.reorderableValue) {
      this.disconnectReorderable();
    }
  }

  disconnectReorderable() {
    this.sortable.destroy();
  }
}
