import { Node, mergeAttributes } from '@tiptap/core'

function attributesToParams(attributes) {
  const params = Object.fromEntries(
    Object.entries(attributes).map(([k, v]) => [`prompt[${k}]`, v])
  );
  return new URLSearchParams(params).toString();
}

export default Node.create({
  name: 'promptsBlock',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      color: {
        default: null,
      },
      href: {
        default: null,
      },
      target: {
        default: null,
      },
      text: {
        default: "",
      },
      title: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'prompts-block',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['prompts-block', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ({ editor, node, getPos, HTMLAttributes, decorations, extension }) => {
      const dom = document.createElement('prompts-block');
      const params = attributesToParams(node.attrs);

      dom.innerHTML = `
        <button type="button" class="color-${node.attrs.color}" title="${node.attrs.title || ''}" data-action="admin--form--content-editor#togglePrompts admin--layout#openPanel" data-params="${params}">${node.attrs.text}</button>
      `;

      return {
        dom,
      }
    };
  },
})
