import "./list_component.css";
import Sortable from 'sortablejs';

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = [ "orderInput" ];
  static values = { reorderable: Boolean };

  connect() {
    if (this.reorderableValue) {
      this.connectReorderable();
    }
  }

  connectReorderable() {
    this.sortable = Sortable.create(
      this.element,
      {
        animation: 150,
        draggable: ".c-admin--list--item-root",
        fallbackOnBody: true,
        group: this.identifier,
        swapThreshold: 0.65,
        store: {
          set: this.updateOrder.bind(this),
        }
      }
    );
  }

  updateOrder(sortable) {
    const order = sortable.toArray();
    const orderHash = order.reduce((hash, id, index) => {
      hash[id] = index;
      return hash;
    }, {});
    this.orderInputTarget.value = JSON.stringify(orderHash);
  }

  disconnect() {
    if (this.reorderableValue) {
      this.disconnectReorderable();
    }
  }

  disconnectReorderable() {
    this.sortable.destroy();
  }
}
