import "./heading_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
export class Controller extends BaseController {
  static targets = ["time"];

  connect() {
    if (this.hasTimeTarget) {
      this.updateTime();
      this.timeInterval = setInterval(() => this.updateTime(), 1000);
    }
  }

  disconnect() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  updateTime() {
    this.timeTarget.innerHTML = this.currentTime;
  }

  remove() {
    while (this.turboFrame.firstChild) {
      this.turboFrame.removeChild(this.turboFrame.firstChild);
    }
    this.turboFrame.removeAttribute("src");
  }

  get currentTime() {
    const date = new Date();
    const options = {
      timeZone: "Europe/Paris",
      hour: "2-digit",
      minute: "2-digit",
    };
    const time = date.toLocaleTimeString(this.currentLocale, options);
    return time.replace(":", "<span>:</span>");
  }

  get currentLocale() {
    return document.documentElement.lang;
  }

  get turboFrame() {
    return this.element.closest("turbo-frame");
  }
}
