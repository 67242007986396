import "./tabs_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["tablist"];

  connect() {
    this.cache = {};

    Array.prototype.forEach.call(this.tabs, tab => {
      this.cache[tab.hash] = [
        tab,
        document.getElementById(tab.getAttribute("aria-controls"))
      ];

      if (tab.getAttribute("aria-selected") === "true") {
        this.last = this.cache[tab.hash];
        this.cache[""] = this.last;
      } else {
        tab.setAttribute("tabindex", -1);
      }
    });

    window.addEventListener("hashchange", () => this.onHashChange());
    this.onHashChange();
  }

  focusTab(tab) {
    if (!tab) return;
    window.location.hash = tab.hash;
    tab.focus();
  }

  onTabKeydown(e) {
    let move;

    if (e.keyCode === 37) {
      move = -1;
    } else if (e.keyCode === 39) {
      move = 1;
    }

    if (!move) return;
    const index = Array.prototype.indexOf.call(this.tabs, e.target);
    const next = this.tabs[index + move];
    this.focusTab(next);
  }

  onHashChange() {
    const tab = this.cache[window.location.hash];

    if (tab) {
      if (this.last) {
        this.last[0].removeAttribute("aria-selected");
        this.last[0].setAttribute("tabindex", -1);
        this.last[1].setAttribute("hidden", "");
      }

      tab[0].setAttribute("aria-selected", "true");
      tab[0].removeAttribute("tabindex");
      tab[1].removeAttribute("hidden", "");

      this.last = tab;
    }
  }

  get tabs() {
    return this.tablistTarget.querySelectorAll('[href^="#"][role="tab"]');
  }
}
